exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-artykuly-js": () => import("./../../../src/pages/artykuly.js" /* webpackChunkName: "component---src-pages-artykuly-js" */),
  "component---src-pages-cmentarz-js": () => import("./../../../src/pages/cmentarz.js" /* webpackChunkName: "component---src-pages-cmentarz-js" */),
  "component---src-pages-cmentarz-szukaj-js": () => import("./../../../src/pages/cmentarz/szukaj.js" /* webpackChunkName: "component---src-pages-cmentarz-szukaj-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-multimedia-js": () => import("./../../../src/pages/multimedia.js" /* webpackChunkName: "component---src-pages-multimedia-js" */),
  "component---src-pages-ogloszenia-i-intencje-js": () => import("./../../../src/pages/ogloszenia-i-intencje.js" /* webpackChunkName: "component---src-pages-ogloszenia-i-intencje-js" */),
  "component---src-templates-all-graves-js": () => import("./../../../src/templates/all-graves.js" /* webpackChunkName: "component---src-templates-all-graves-js" */),
  "component---src-templates-annoucement-js": () => import("./../../../src/templates/annoucement.js" /* webpackChunkName: "component---src-templates-annoucement-js" */),
  "component---src-templates-article-current-js": () => import("./../../../src/templates/articleCurrent.js" /* webpackChunkName: "component---src-templates-article-current-js" */),
  "component---src-templates-article-main-js": () => import("./../../../src/templates/articleMain.js" /* webpackChunkName: "component---src-templates-article-main-js" */),
  "component---src-templates-grave-js": () => import("./../../../src/templates/grave.js" /* webpackChunkName: "component---src-templates-grave-js" */),
  "component---src-templates-kalendarz-intencji-js": () => import("./../../../src/templates/kalendarz-intencji.js" /* webpackChunkName: "component---src-templates-kalendarz-intencji-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */)
}

